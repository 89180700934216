import cx from "classnames"
import useTranslation from "src/hooks/useTranslation"

import styles from "./styles.module.scss"

export default function PageAlert({ content }) {
  const { t } = useTranslation()
  if (typeof content === "string") {
    content = { text: content, type: "info" }
  }

  let icon = content.icon ?? null
  if (content.type === "info") {
    icon = "info circle"
  }

  if (icon) {
    icon = <i className={cx("ui icon", icon)} />
  }

  return (
    <div className={cx("ui message", content.type, content.className, styles.alert)}>
      {icon}
      {t(content.text)}
    </div>
  )
}
