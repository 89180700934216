import { useContext } from "react"
import { ConfirmationModalContext } from "../providers/ConfirmationModalProvider"

export default function useConfirmationModal({
  title = null,
  question = null,
  rightButtonText = null,
  buttons = {
    left: {
      text: "No",
      name: "normal"
    },
    right: {
      text: "Sí",
      name: "warning"
    }
  },
  className = null,
  style = {}
}) {
  const { confirmationModalConfig, setConfirmationModalConfig } = useContext(ConfirmationModalContext)

  if (rightButtonText && typeof buttons?.right !== undefined) {
    buttons["right"]["text"] = rightButtonText
  }

  const openConfirmationModal = (
    requestConfig = {
      title: null,
      request: async () => {},
      onSuccess: (res) => {},
      onError: () => {}
    }
  ) => {
    setConfirmationModalConfig({
      ...confirmationModalConfig,
      isOpen: true,
      title: requestConfig.title ?? title,
      question,
      requestConfig,
      buttons,
      className,
      style
    })
  }

  const showConfirm = (question, rightButtonText = null) => {
    const _buttons = { ...buttons }
    if (rightButtonText) {
      _buttons["right"]["text"] = rightButtonText
    } else {
      _buttons["right"]["text"] = "Sí"
    }
    return new Promise((resolve) => {
      setConfirmationModalConfig({
        isOpen: true,
        question,
        buttons: _buttons,
        requestConfig: {
          onSuccess: (r) => {
            resolve(r)
          }
        }
      })
    })
  }

  return { openConfirmationModal, showConfirm }
}
