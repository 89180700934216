import { useEffect, useState } from "react"
import useAccessControl from "src/hooks/useAccessControl"
import axiosApiInstance from "src/services/httpInterceptor"
import styles from "./sleekplan.module.scss"

export default function SleekWidget() {
  const { getCurrentUser } = useAccessControl()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_SLEEK_PRODUCT_ID) {
      return
    }
    window.$sleek = []
    window.SLEEK_PRODUCT_ID = process.env.NEXT_PUBLIC_SLEEK_PRODUCT_ID
    window.$sleek.sso = function (callback) {
      axiosApiInstance.get("/plugin/integrations/sleekplan/").then(({ data }) => {
        callback({ token: data.ssoToken })
      })
    }
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted) {
      const s = document.createElement("script")
      s.async = 1
      s.type = "text/javascript"
      s.src = "https://client.sleekplan.com/sdk/e.js"
      s.onload = () => {
        const currentUser = getCurrentUser()
        if (currentUser) {
          window.$sleek.setUser({
            mail: currentUser.email,
            id: currentUser.id,
            name: currentUser.name.toLowerCase()
          })
          window.$sleek.addMetadata({
            account_name: currentUser.account_name,
            role: currentUser.role,
            user_tyoe: currentUser.user_type
          })
        }
      }
      document.getElementById("sleek-scripts").appendChild(s)
    }
  }, [mounted])

  if (!process.env.NEXT_PUBLIC_SLEEK_PRODUCT_ID) {
    return null
  }

  return (
    mounted && (
      <>
        <div id='sleek-scripts' style={{ display: "none" }} />
        <div className={styles.button}>
          <button data-sleek-changelog data-badge-changelog className='ui button icon tertiary compact'>
            <i className='ui icon bell large green' />
          </button>
        </div>
      </>
    )
  )
}
