import cx from "classnames"
import Link from "next/link"
import { useRef, useState } from "react"
import Icon from "src/components/Icon"
import ButtonLogout from "src/macrocomponents/ButtonLogout"
import useAuthentication from "../../hooks/useAuthentication"
import styles from "./style.module.scss"

export const UserIcon = function ({ className = null }) {
  const { isAuthenticated } = useAuthentication()
  const [isShowingUserInfo, setIsShowingUserInfo] = useState(false)
  const showTimerRef = useRef(null)
  const hideTimerRef = useRef(null)

  if (!isAuthenticated) {
    return null
  }

  let user = typeof window !== "undefined" ? localStorage.getItem("user") : null
  if (!user) {
    return null
  }

  const onMouseEnter = () => {
    showTimerRef.current = setTimeout(() => {
      setIsShowingUserInfo(true)
      if (hideTimerRef.current) {
        clearTimeout(hideTimerRef.current)
      }
    }, 800)
  }

  const onMouseLeave = () => {
    if (showTimerRef.current) {
      clearTimeout(showTimerRef.current)

      hideTimerRef.current = setTimeout(() => {
        close()
      }, 600)
    }
  }

  const onMouseMove = () => {
    if (hideTimerRef.current) {
      clearTimeout(hideTimerRef.current)
    }
  }

  const onClick = () => {
    setIsShowingUserInfo(true)
  }

  const close = () => {
    setIsShowingUserInfo(false)
  }

  const { sigla } = JSON.parse(user)

  return (
    <div
      className={cx(styles.userInfoWrapper, className)}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div onClick={onClick}>
        <Icon name='sidenav/my-profile' className={styles.userIcon} />
        <span className={styles.userIconSigla}>{sigla}</span>
      </div>
      <UserCardInfo isShowingUserInfo={isShowingUserInfo} />
    </div>
  )
}

export default function UserCardInfo(props) {
  const { isShowingUserInfo } = props
  const { name, sigla, email, user_type } = JSON.parse(localStorage.getItem("user"))

  return isShowingUserInfo ? (
    <div className={styles.user_info}>
      <div className={styles.user_info__photo}></div>
      <p className={styles.user_info__text}>{name}</p>
      <span className={styles.user_info__text}>({sigla})</span>
      <p title={email} className={cx(styles.user_info__text, styles.truncate)}>
        {email}
      </p>
      <div style={{ margin: "25px 10px" }}>
        {user_type != "EXTERNO" && (
          <>
            <Link href={{ pathname: "/capacitaciones" }}>
              <a className={styles.user_info__button__outline__secondary}>
                <Icon name='font-awesome/calendar-regular' size='13' color='var(--primary-green)' /> Capacitaciones
              </a>
            </Link>
            <Link href={{ pathname: "/certificaciones" }}>
              <a className={styles.user_info__button__outline__secondary}>
                <Icon name='font-awesome/user_circle' size='13' color='var(--primary-green)' /> Certificados
              </a>
            </Link>
          </>
        )}
        <Link href={{ pathname: "/profile" }}>
          <a className={styles.user_info__button__outline__secondary}>
            <Icon name='font-awesome/user_circle' size='13' color='var(--primary-green)' /> Ver mi perfil
          </a>
        </Link>
      </div>

      <ButtonLogout />
    </div>
  ) : null
}
