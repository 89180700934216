import cx from "classnames"
import { useTranslation } from "react-i18next"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import useDispatch from "src/store"
import { logout } from "../../hooks/useAuthentication"
import styles from "./style.module.scss"

export default function ButtonLogout({ onClick = null, className = null, icon = true, style = {} }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { openConfirmationModal: logoutConfirmModal } = useConfirmationModal({
    title: t("main_ui.logout.btn"),
    question: t("main_ui.logout.confirmation_text")
  })

  return (
    <button
      className={cx(styles.button, className)}
      style={style}
      onClick={(ev) => {
        ev.preventDefault()
        if (onClick) {
          onClick()
        }
        logoutConfirmModal({
          request: () => {
            logout(dispatch, localStorage, true)
          }
        })
      }}
    >
      {icon && <i className='icon sign out' style={{ marginRight: 10 }} />}

      {t("main_ui.logout.btn")}
    </button>
  )
}
