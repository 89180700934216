import { useEffect } from "react"
import { useSelector } from "react-redux"
import BreadCrumbs from "src/components/BreadCrumbs"
import { UnauthorizedBlock } from "src/components/Messages/UnauthorizedBlock"
import PageAlert from "src/components/PageAlert"
import { UserIcon } from "src/components/UserCardInfo"
import ErrorBoundary from "src/errors/ErrorBoundary"
import useAccessControl from "src/hooks/useAccessControl"
import usePageHead from "src/hooks/usePageHead"
import SleekWidget from "src/thirdparty/sleekplan"
import styles from "./style.module.scss"

export default function ContentLayout({
  children,
  pageTitle = null,
  breadCrumbConfig = null,
  style = {},
  permission = null
}) {
  const { setPageTitle, pageTitle: prevPageTitle } = usePageHead()

  const topAlerts = useSelector((state) => state.page?.topAlerts)

  const { hasPermission } = useAccessControl()

  useEffect(() => {
    if (pageTitle !== null && pageTitle !== prevPageTitle) {
      setPageTitle(pageTitle)
    }
  }, [pageTitle])

  const renderContent = () => {
    if (permission && !hasPermission(permission)) {
      return (
        <div className='ui container'>
          <UnauthorizedBlock />
        </div>
      )
    }

    return <ErrorBoundary>{children}</ErrorBoundary>
  }

  return (
    <div className={styles.wrapper} style={style}>
      <SleekWidget />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <BreadCrumbs list={breadCrumbConfig} />
        <UserIcon className='layout-icon' />
      </div>
      {topAlerts?.length > 0 && (
        <div>
          {topAlerts?.map(({ alert }, k) => {
            return <PageAlert key={k} content={alert} />
          })}
        </div>
      )}
      {renderContent()}
    </div>
  )
}
