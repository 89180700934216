import useTranslation from "src/hooks/useTranslation"

export const UnauthorizedBlock = () => {
  const { t } = useTranslation()

  return (
    <div className='ui icon message massive centered'>
      <i className='icon lock' />
      <div className='content'>
        <div className='header'>{t("main_ui.general.error_access_denied")}</div>
        <p>{t("main_ui.general.error_unauthorized_page")}</p>
      </div>
    </div>
  )
}
