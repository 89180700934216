export const getParameterValue = (parameter, defaultValue = null) => {
  let result = defaultValue
  let temp = []
  window.location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      temp = item.split("=")
      if (temp[0] === parameter) result = decodeURIComponent(temp[1])
    })
  return result
}

export const cleanParam = (value) => {
  if (value + "" === "null" || value + "" === "undefined") {
    return null
  }

  return value
}

export const parseRouter = (route, router, exclude = []) => {
  const currentRouterQuery = {}
  for (const [k, value] of Object.entries(router.query)) {
    if (exclude.includes(k)) {
      continue
    }

    if (!(value === null || value === undefined || value.length === 0)) {
      currentRouterQuery[k] = value
    }
  }
  const searchParams = new URLSearchParams({ ...currentRouterQuery, ...route?.query })

  let pathname = route.pathname ?? router.pathname
  if (!pathname.endsWith("/")) {
    pathname += "/"
  }

  if (searchParams.toString().length > 0) {
    return `${pathname}?${searchParams.toString()}`
  }

  return `${pathname}`
}

export const redirectToHomepage = () => {
  window.location.href = "/"
}

export function genUrl(url) {
  return `${process.env.webAppUrl}/${url}`
}
