import cx from "classnames"
import { useRouter } from "next/router"

import routes from "src/constants/routes"
import { parseRouter } from "src/helpers/router"
import useTranslation from "src/hooks/useTranslation"
import Helptip from "../Helptip"
import styles from "./style.module.scss"

export default function BreadCrumbs({ list = null, link = true, className = null }) {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <ul className={cx(styles.breadcrumb, className)}>
      {list?.map((element, i) => {
        if (element === null || typeof element === "undefined") {
          return null
        }
        if (Array.isArray(element)) {
          element = { name: element[0], router: element[1] }
        } else if (typeof element == "string") {
          if (element.startsWith("@")) {
            element = { name: element.substr(1), router: routes[element]?.router ?? null }
          } else {
            element = { name: element, router: null }
          }
        }

        if (typeof element.name === "undefined") {
          return null
        }

        const elementName = t(
          Array.isArray(element.name) ? element.name.map((x) => `page.${x}`) : "page." + element.name,
          element.name
        )

        return list.length - 1 === i ? (
          <li key={i} className={styles.active}>
            <span>{elementName}</span>
            {element.tooltip && (
              <Helptip name={element.tooltip} title={elementName} tooltipPosition={"right"} iconSize={12} />
            )}
          </li>
        ) : (
          <li key={i} title={elementName}>
            {link && element.router ? (
              <a href={parseRouter(element.router, router, ["auditoria-id", "evaluacion-id"])}>{elementName}</a>
            ) : (
              <span>{elementName}</span>
            )}
          </li>
        )
      })}
    </ul>
  )
}
